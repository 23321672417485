import { Location, NgTemplateOutlet } from '@angular/common';
import * as i0 from '@angular/core';
import { TemplateRef, Component, ViewChild, input, EventEmitter, inject, computed, Output, HostBinding, ContentChild, ChangeDetectorRef, Injector, Directive, Input, Injectable } from '@angular/core';
import { CrmUnsubscribeDirective, CrmUrlParamsService, CrmSharedSubject, crmResolveExpression, mergeDeep } from 'common-module/core';
import { CrmPageHeaderWrapperComponent } from 'common-module/page-header';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject, of } from 'rxjs';
import { tap, take, switchMap, debounceTime, takeUntil, skip, map } from 'rxjs/operators';
import { cloneDeep, omit, isEmpty } from 'lodash-es';
import { CrmStateService } from 'common-module/state';
const _c0 = ["*"];
function CrmGeneralCompositeContentBodyComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
function CrmGeneralCompositeContentHeaderComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
function CrmGeneralCompositeComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-page-header-wrapper", 1);
  }
  if (rf & 2) {
    i0.ɵɵproperty("config", ctx);
  }
}
function CrmGeneralCompositeComponent_Conditional_2_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CrmGeneralCompositeComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrmGeneralCompositeComponent_Conditional_2_ng_container_0_Template, 1, 0, "ng-container", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.contentHeader.template);
  }
}
function CrmGeneralCompositeComponent_Conditional_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CrmGeneralCompositeComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, CrmGeneralCompositeComponent_Conditional_3_ng_container_1_Template, 1, 0, "ng-container", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.contentBody.template);
  }
}
class CrmGeneralCompositeContentBodyComponent {
  static {
    this.ɵfac = function CrmGeneralCompositeContentBodyComponent_Factory(t) {
      return new (t || CrmGeneralCompositeContentBodyComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmGeneralCompositeContentBodyComponent,
      selectors: [["crm-general-content-body"]],
      viewQuery: function CrmGeneralCompositeContentBodyComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(TemplateRef, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function CrmGeneralCompositeContentBodyComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, CrmGeneralCompositeContentBodyComponent_ng_template_0_Template, 1, 0, "ng-template");
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmGeneralCompositeContentBodyComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-general-content-body',
      template: `
    <ng-template>
      <ng-content></ng-content>
    </ng-template>
  `
    }]
  }], null, {
    template: [{
      type: ViewChild,
      args: [TemplateRef, {
        static: true
      }]
    }]
  });
})();
class CrmGeneralCompositeContentHeaderComponent {
  static {
    this.ɵfac = function CrmGeneralCompositeContentHeaderComponent_Factory(t) {
      return new (t || CrmGeneralCompositeContentHeaderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmGeneralCompositeContentHeaderComponent,
      selectors: [["crm-general-content-header"]],
      viewQuery: function CrmGeneralCompositeContentHeaderComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(TemplateRef, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function CrmGeneralCompositeContentHeaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, CrmGeneralCompositeContentHeaderComponent_ng_template_0_Template, 1, 0, "ng-template");
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmGeneralCompositeContentHeaderComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-general-content-header',
      template: `
    <ng-template>
      <ng-content></ng-content>
    </ng-template>
  `
    }]
  }], null, {
    template: [{
      type: ViewChild,
      args: [TemplateRef, {
        static: true
      }]
    }]
  });
})();

/**
 * General component for component composing. Contains page header and content outlet
 * for more content.
 */
class CrmGeneralCompositeComponent extends CrmUnsubscribeDirective {
  constructor() {
    super(...arguments);
    /**
     * Page header config
     */
    this.headerConfig = input();
    /**
     * Event emitter for back click handling
     */
    this.backClicked = new EventEmitter();
    this.hostClass = 'crm-general-composite';
    this.location = inject(Location);
    this.config = computed(() => {
      const config = this.headerConfig();
      if (!config) {
        return undefined;
      }
      return {
        ...config,
        backClick: config?.backClick ?? (() => this.defaultBackClick())
      };
    });
  }
  defaultBackClick() {
    if (!this.backClicked.observed) {
      this.location.back();
      return;
    }
    this.backClicked.emit();
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmGeneralCompositeComponent_BaseFactory;
      return function CrmGeneralCompositeComponent_Factory(t) {
        return (ɵCrmGeneralCompositeComponent_BaseFactory || (ɵCrmGeneralCompositeComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmGeneralCompositeComponent)))(t || CrmGeneralCompositeComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmGeneralCompositeComponent,
      selectors: [["crm-general"]],
      contentQueries: function CrmGeneralCompositeComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, CrmGeneralCompositeContentHeaderComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, CrmGeneralCompositeContentBodyComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentHeader = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentBody = _t.first);
        }
      },
      hostVars: 2,
      hostBindings: function CrmGeneralCompositeComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        headerConfig: [i0.ɵɵInputFlags.SignalBased, "headerConfig"]
      },
      outputs: {
        backClicked: "backClicked"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 3,
      consts: [[1, "crm-general-composite-header"], [3, "config"], [1, "crm-general-composite-body"], [4, "ngTemplateOutlet"]],
      template: function CrmGeneralCompositeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, CrmGeneralCompositeComponent_Conditional_1_Template, 1, 1, "crm-page-header-wrapper", 1)(2, CrmGeneralCompositeComponent_Conditional_2_Template, 1, 1, "ng-container");
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(3, CrmGeneralCompositeComponent_Conditional_3_Template, 2, 1, "div", 2);
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵadvance();
          i0.ɵɵconditional(1, (tmp_0_0 = ctx.config()) ? 1 : -1, tmp_0_0);
          i0.ɵɵadvance();
          i0.ɵɵconditional(2, ctx.contentHeader ? 2 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(3, ctx.contentBody ? 3 : -1);
        }
      },
      dependencies: [NgTemplateOutlet, CrmPageHeaderWrapperComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmGeneralCompositeComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-general',
      template: `
    <div class="crm-general-composite-header">
      @if (config(); as config) {
        <crm-page-header-wrapper [config]="config"></crm-page-header-wrapper>
      }

      @if (contentHeader) {
        <ng-container *ngTemplateOutlet="contentHeader.template"></ng-container>
      }
    </div>

    @if (contentBody) {
      <div class="crm-general-composite-body">
        <ng-container *ngTemplateOutlet="contentBody.template"></ng-container>
      </div>
    }
  `,
      imports: [NgTemplateOutlet, CrmPageHeaderWrapperComponent]
    }]
  }], null, {
    backClicked: [{
      type: Output
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }],
    contentHeader: [{
      type: ContentChild,
      args: [CrmGeneralCompositeContentHeaderComponent]
    }],
    contentBody: [{
      type: ContentChild,
      args: [CrmGeneralCompositeContentBodyComponent]
    }]
  });
})();

/**
 * Base class for composite components that using data providers and state for their
 * data, config and more.
 * Internal examples are ListComponent, DetailComponent, TableComponent, ...
 *
 * This class take care of initialization of data provider either from route data
 * or from input property `dataProvider` and its initialization with state and necessary
 * subscriptions.
 *
 * Generic type S is for StateService type used in child implementation.
 */
class CrmCompositeComponent extends CrmUnsubscribeDirective {
  constructor() {
    super(...arguments);
    this.ref = inject(ChangeDetectorRef);
    this.ar = inject(ActivatedRoute);
    this.inj = inject(Injector);
  }
  /**
   * Component state service instance to subscribe internal state to.
   *
   * @public
   */
  get stateService() {
    return this.dataProvider.stateService;
  }
  ngOnInit() {
    this.initFromRouteData().subscribe(_ => {
      this.onInit();
      this.dataProvider.afterInit();
    });
  }
  /**
   * Lifecycle method that is called when internal init method resolved.
   */
  onInit() {
    // Component initialize implementation
  }
  /**
   * Lifecycle method that is called on when `ngOnDestroy` angular lifecycle
   * method is called and is handled via `destroyed$` subsciption initialized in `initDestroySubscription`
   */
  onDestroy() {}
  /**
   * Called when component is subscribed to component state.
   * Map config object from state to internal component config structure.
   *
   * @param config
   */
  onConfigUpdate(config) {
    // Here map config from state to component properties
    this.config = config;
  }
  /**
   * Called when component is subscribed to component state.
   * Map data from state to internal data structure.
   *
   * @param data
   */
  onDataUpdate(data) {
    // Here map dat from state to component properties
    this.data = data;
  }
  /**
   * Component initialization. Initialize componentState property with dataProvider state.
   *
   * Here is handled dataProvider initialization using its `onInit` method.
   * Component state is initialized also.
   *
   * @param viewKey       String key that differentiate instances of same view
   */
  initComponent(viewKey) {
    return this.dataProvider.onInit({
      viewKey,
      activatedRoute: this.ar
    }).pipe(tap(() => {
      this.initDestroySubscription();
      this.initState();
    }));
  }
  initState() {
    this.subscribeData();
    this.subscribeConfig();
  }
  initDataProviderOperation(_params, data) {
    if (this.dataProvider) {
      return;
    }
    const {
      dataProvider
    } = data;
    if (!dataProvider) {
      throw new Error(`Undefined data provider for '${data.viewKey}'!`);
    }
    this.dataProvider = this.inj.get(dataProvider);
  }
  /**
   * Action called after data provider is initalized from route data.
   * Custom initialization can be done here if overridden.
   *
   * @param routeParams
   * @param routeData
   * @protected
   */
  onInitFromRouteData(routeParams, routeData) {
    this.dataProvider.onInitFromRouteData(routeParams, routeData);
  }
  /**
   * Initializes dataProvider instance from URL params. If injection token is not present in
   * params, default dataProvider is instantiated.
   * Then cals `initComponent` method
   */
  initFromRouteData() {
    return combineLatest([this.ar.params, this.ar.data]).pipe(take(1), tap(([params, data]) => this.initDataProviderOperation(params, data)), tap(([params, data]) => this.onInitFromRouteData(params, data)), switchMap(([_, data]) => this.initComponent(data.viewKey)));
  }
  /**
   * Subscribe to component state config object.
   * On change calls `onConfigUpdate` to update internal config properties.
   */
  subscribeConfig() {
    this.stateService.config$.pipe(debounceTime(0), takeUntil(this.destroyed$)).subscribe(config => {
      this.onConfigUpdate(config);
      this.ref.markForCheck();
    });
  }
  /**
   * Subscribe to component state data object.
   * On change calls `onDataUpdate` to update internal data property.
   */
  subscribeData() {
    this.stateService.data$.pipe(debounceTime(0), takeUntil(this.destroyed$)).subscribe(data => {
      this.onDataUpdate(data);
      this.ref.markForCheck();
    });
  }
  /**
   * Subscribes to `destroyed$` Subject, calls `onDestroy` lifecycle method
   * and calls `dataProvider.onDestroy` method.
   */
  initDestroySubscription() {
    this.destroyed$.subscribe(() => {
      this.onDestroy();
      this.dataProvider.onDestroy();
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmCompositeComponent_BaseFactory;
      return function CrmCompositeComponent_Factory(t) {
        return (ɵCrmCompositeComponent_BaseFactory || (ɵCrmCompositeComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmCompositeComponent)))(t || CrmCompositeComponent);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmCompositeComponent,
      inputs: {
        dataProvider: "dataProvider"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmCompositeComponent, [{
    type: Directive
  }], null, {
    dataProvider: [{
      type: Input
    }]
  });
})();
class CrmCompositeProvider {
  constructor() {
    this._dataLoading$ = new Subject();
    this._afterInit$ = new Subject();
    /**
     * Provide the UrlParamsService instance manually in child class.
     * It is not necessary to work proper.
     *
     * @protected
     */
    this._urlParamsService = inject(CrmUrlParamsService);
    /**
     * Destroy subject that fires event on `ngOnDestroy` is called.
     *
     * @protected
     */
    this._destroy$ = new Subject();
    /**
     * Persist filters to URL params.
     * Default value: `true`
     *
     * @protected
     */
    this.persistUrlParams = true;
    this.injector = inject(Injector);
  }
  /**
   * Returns component state service
   */
  get stateService() {
    return this._stateService;
  }
  /**
   * Returns component state service
   */
  get state() {
    return this.stateService.state;
  }
  /**
   * Returns component state service
   */
  get afterInit$() {
    return this._afterInit$.asObservable().pipe(take(1));
  }
  ngOnDestroy() {
    this._destroy$.next(true);
    this._urlParamsService.clearParams(this.viewKey);
  }
  /**
   * Triggers reload of data only
   *
   * @param params
   */
  reloadData(params) {
    this._reloadDataSubject$.next(params);
  }
  /**
   * Triggers reload of whole component. Including config, columns and data.
   *
   * @param params
   */
  reload(params) {
    this._reloadSubject$.next(params);
  }
  /**
   * Called by `initComponent` method of component using this data provider.
   * Note: component state is not initialized yet.
   *
   * @param config
   */
  onInit(config) {
    const {
      viewKey = '',
      activatedRoute
    } = config;
    this.viewKey ??= viewKey;
    this._activatedRoute = activatedRoute;
    this._stateService = this.getStateInstance();
    this.initReloadSubscriptions();
    return this.initFromQueryParams().pipe(tap(() => {
      this._activatedRoute.params.pipe(skip(1)).subscribe(params => this.handleParamsChange(params));
      this._activatedRoute.queryParams.pipe(skip(1)).subscribe(params => this.handleQueryParamsChange(this.decodeQueryParams(params)));
    }));
  }
  onInitFromRouteData(routeParams, routeData) {
    this._params = routeParams;
    this._data = routeData;
  }
  /**
   * Called by component that have this class as a provider, after component is initialized
   */
  afterInit() {
    this.loadComponentData();
    this._afterInit$.next();
  }
  /**
   * This method is call if data load for component is needed.
   * Called from init and reload methods
   *
   * Override if different data parsing is needed
   *
   * @param params
   */
  onDataLoad(params) {
    this.updateUrlParams(params);
    return this._loadMethod(params).pipe(takeUntil(this._destroy$), tap(data => this._stateService.updateData(data)));
  }
  /**
   * Set callback function that is called when table reload is invoked
   *
   * @param func
   */
  onDataReload(func) {
    this._reloadDataSubject$.pipe([takeUntil(this._destroy$)]).subscribe(data => {
      if (func) {
        func(data);
      }
    });
  }
  /**
   * Set callback function that is called when table reload is invoked
   *
   * @param func
   */
  onReload(func) {
    this._reloadSubject$.pipe([takeUntil(this._destroy$)]).subscribe(() => {
      if (func) {
        func();
      }
    });
  }
  /**
   * Called when component is destroyed
   */
  onDestroy() {
    this.ngOnDestroy();
  }
  serializeQueryParams(params) {
    if (!params) {
      return {};
    }
    return Object.entries(cloneDeep(params)).reduce((result, [key, value]) => {
      result[key] = this.serializeQueryParamValue(value);
      return result;
    }, {});
  }
  deserializeQueryParams(params) {
    return cloneDeep(params);
  }
  serializeQueryParamValue(value) {
    if (value instanceof Date) {
      return value.toISOString();
    }
    if (Array.isArray(value)) {
      return value.map(this.serializeQueryParamValue).join(',');
    }
    return value;
  }
  /**
   * Creates default state service config
   *
   * @protected
   */
  getStateServiceConfig() {
    return {
      initialState: {
        loading: false,
        config: {},
        data: {}
      },
      configHook: () => this.getConfigHook(),
      dataHook: () => this.getDataHook()
    };
  }
  /**
   * Method that returns a component config or Observable with a component config
   * that is used in `setComponentConfig` to be set to state.
   *
   * @protected
   */
  getComponentConfig(_params) {
    return {};
  }
  initFromQueryParams() {
    return new CrmSharedSubject(this.getQueryParams()).pipe([take(1), ...this.getQueryOperators()]).pipe(switchMap(() => this.initComponentState()));
  }
  getQueryOperators() {
    return [tap(params => {
      this._queryParams = omit(params, Object.keys(this._params));
    })];
  }
  /**
   * Returns a query params from an url if there is any.
   *
   * @protected
   */
  getQueryParams() {
    return this._activatedRoute.queryParams.pipe(takeUntil(this._destroy$), map(query => this.decodeQueryParams(query)));
  }
  decodeQueryParams(params) {
    return this.deserializeQueryParams(this._urlParamsService.decodeParams(this.viewKey, !isEmpty(params) ? params : {}) ?? {});
  }
  updateUrlParams(params) {
    if (this.persistUrlParams) {
      this._urlParamsService.updateContextParams(this.viewKey, this.serializeQueryParams(params));
    }
  }
  /**
   * Callback function to handle route params change
   *
   * @param params
   * @protected
   */
  handleParamsChange(params) {
    this._params = params;
  }
  /**
   * Callback function to handle route query params change
   *
   * @param params
   * @protected
   */
  handleQueryParamsChange(params) {
    this._queryParams = params;
  }
  /**
   * Calls `onLoad` method without necessity to subscribe.
   * Parameters are serialized to URL params.
   *
   * On subscribe loading is handled.
   *
   * @param params
   * @protected
   */
  loadComponentData(params) {
    this._stateService.setLoading(true);
    this.onDataLoad(params ?? {}).subscribe(() => {
      this._stateService.setLoading(false);
    });
  }
  /**
   * Initializes component state with necessary subscriptions
   *
   * @param params
   * @private
   */
  initComponentState(params) {
    return this.setComponentConfig(params);
  }
  /**
   * Extracted async operation that is executed on component reload invocation.
   *
   * @param params
   * @protected
   */
  reloadOperation(params) {
    return this.initComponentState(params);
  }
  /**
   * Extracted async operation that is executed on a component data reload invocation.
   *
   * @param params
   * @protected
   */
  reloadDataOperation(params) {
    return this.onDataLoad({
      ...params
    });
  }
  /**
   * Reload subscription initialization. Subscriptions for reloadData and reload actions.
   *
   * @private
   */
  initReloadSubscriptions() {
    this._reloadDataSubject$ = new CrmSharedSubject();
    this._reloadDataSubject$.pipe([takeUntil(this._destroy$), tap(() => this._stateService.setLoading(true)), switchMap(params => this.reloadDataOperation(params))]).subscribe(() => {
      this._stateService.setLoading(false);
    });
    this._reloadSubject$ = new CrmSharedSubject();
    this._reloadSubject$.pipe([takeUntil(this._destroy$), tap(() => this._stateService.setLoading(true)), switchMap(params => this.reloadOperation(params))]).subscribe(() => {
      this._stateService.setLoading(false);
    });
  }
  getConfigHook() {
    return tap(_ => {});
  }
  getDataHook() {
    return tap(_ => {});
  }
  /**
   * Set a component config to the component state with value from `getComponentConfig`.
   *
   * @private
   */
  setComponentConfig(params) {
    return crmResolveExpression({
      resolvable: this.getComponentConfig(params)
    }).pipe(takeUntil(this._destroy$), tap(config => this._stateService.updateConfig(config)));
  }
  /**
   * Load method that is used in `onDataLoad` method
   *
   * @param _params
   */
  _loadMethod(_params) {
    return of({});
  }
  static {
    this.ɵfac = function CrmCompositeProvider_Factory(t) {
      return new (t || CrmCompositeProvider)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmCompositeProvider,
      factory: CrmCompositeProvider.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmCompositeProvider, [{
    type: Injectable
  }], null, null);
})();

/**
 * General state class for a composite components like the ListComponent, DetailComponent and the TableComponent.
 * Provides prepared a observable state for a loading and configuration, that is shared between a components.
 */
class CrmCompositeStateService extends CrmStateService {
  constructor(options) {
    super(options.initialState ?? {
      loading: true,
      config: {},
      data: {}
    });
    this.options = options;
    this.config$ = this.select(state => state.config, options.configHook);
    this.data$ = this.select(state => state.data, options.dataHook);
    this.loading$ = this.select(state => state.loading);
  }
  /**
   * Operation to update the config state
   *
   * @param config New config state
   * @param strategy
   */
  updateConfig(config, strategy = 'overwrite') {
    if (strategy === 'overwrite') {
      this.setState({
        config: {
          ...config
        }
      });
      return;
    }
    const state = this.state.config ?? {};
    const newState = mergeDeep(state, {
      ...config
    });
    this.setState({
      config: {
        ...newState
      }
    });
  }
  /**
   * Operation to update the data state
   *
   * @param data New data state
   * @param strategy
   */
  updateData(data, strategy = 'overwrite') {
    if (strategy === 'overwrite') {
      this.setState({
        data
      });
      return;
    }
    const state = this.state.data ?? {};
    const newState = mergeDeep(state, {
      ...data
    });
    this.setState({
      data: {
        ...newState
      }
    });
  }
  /**
   * Operation to update the loading state
   *
   * @param loading
   */
  setLoading(loading) {
    this.setState({
      loading
    });
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { CrmCompositeComponent, CrmCompositeProvider, CrmCompositeStateService, CrmGeneralCompositeComponent, CrmGeneralCompositeContentBodyComponent, CrmGeneralCompositeContentHeaderComponent };
