import { cloneDeep } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

/**
 * Class StateService is simplified state management service.
 */
class CrmStateService {
    constructor(initialState) {
        this.initialState = cloneDeep(initialState);
        this.state$ = new BehaviorSubject(this.initialState);
    }
    get state() {
        return this.state$.getValue();
    }
    select(mapFn, hook) {
        const obs = this.state$.asObservable().pipe(debounceTime(0), map((state) => mapFn?.(state) ?? state), distinctUntilChanged());
        return hook ? obs.pipe(hook()) : obs;
    }
    setState(newState) {
        this.state$.next({
            ...this.state,
            ...newState,
        });
    }
}

/**
 * Generated bundle index. Do not edit.
 */

export { CrmStateService };

